export const endPoints = {
  getRequest: "/api/v1/request/get",
  getAllRequest: "/api/v1/request",
  updateRequest: "/api/v1/request",
  updateCopyRequest: "/api/v1/request/copy",
  getRequestByApprover: "/api/v1/request/approver",
  getRequestByComplianceManagerId: "/api/v1/request/compliance",
  raiseRequest: "/api/v1/request",
  approveTheRequest: "/api/v1/request",
  getUsers: "/api/v1/users",
  getTranslations: "api/v1/langTranslations/",
  updateLangPreference: "/api/v1/users/lang",
  getUser: "/api/v1/users",
  getProfileDetails: "/api/v1/users/details",
  getRequestDetailsByUserId: "/api/v1/request/details",
  uploadAttachments: "/api/v1/attachments/",
  deleteAttachment: "/api/v1/attachments/",
  getUsersHierarchy: "/api/v1/hierarchy/",
  raiseFeedback: "/api/v1/feedback",
  getAllFeedback: "/api/v1/feedback",
  getAPIExceptionLogs: "/api/v1/apiexceptions",
  raiseUiExceptionLog: "/api/v1/uiexceptionlogs",
  getUiExceptionLog: "/api/v1/uiexceptionlogs",
  forwardApproverRequest: "/api/v1/request/forward-approver",
  forwardComplianceRequest: "/api/v1/request/forward-compliance",
  feedbackCount: "/api/v1/feedback/individual-averages-with-percentages",
  getNotifications: "/api/v1/notifications",
  deleteNotification: "/api/v1/notifications",
  // DELEGATIONS
  getAllDelegationsByUid: "/api/v1/delegation/all",
  delegation: "/api/v1/delegation",
  //AuthX End Points
  getEndUsers: "/endusers",
  getUserDetails: "/users",
  getUserDetailsByUid: "/users/uid",
  getUserDetailsByEmail: "/users/email",
  updateLangPreferenceByUid: "/users/uid",
  getProductDetails: "/api/v1/product-details",
  getUserProducts: "/api/v1/products",
  lockProducts: "/api/v1/products/update-locks",
  disableProducts: "/api/v1/products/update-status",
  getLockedProducts: "/api/v1/products/locked-products",
  // NEXUS LANG TRANSLATIONS
  getAllTranslationsNexus: "/translatedb",
  getTranslationsInSingleLanguageNexus: "/getAllLanguages",
  addNewTranslation: "/addLanguage",
  editTranslation: "/updateWordById",
  deleteTranslation: "/deleteWord",
  // getProductDetails:
  //   "https://grc-worker-h6bygfeydrhvh5e3.southindia-01.azurewebsites.net/api/v1/product-details",
  // getUserProducts:
  //   "http://localhost:8100/api/v1/product-details/user",
  // getProductDetails:
  // "https://grc-worker-h6bygfeydrhvh5e3.southindia-01.azurewebsites.net/api/v1/product-details",
  // "http://localhost:8100/api/v1/product-details",
  // getUserProducts:
  // "https://grc-worker-h6bygfeydrhvh5e3.southindia-01.azurewebsites.net/api/v1/product-details/user",
  // "http://localhost:8100/api/v1/product-details/user",
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import WarningIcon from "@mui/icons-material/Warning";
import { useSelector } from "react-redux";
import {
  getNotifications,
  deleteNotification,
  deleteAllNotification,
} from "../../../apiCalls/allApiCalls";

const Notifications = ({ handleCloseMenu, anchorEl }) => {
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { user: profile } = useSelector((state) => state.users);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch notifications when the menu opens
  useEffect(() => {
    if (anchorEl) {
      fetchNotifications();
    }
  }, [anchorEl]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications(profile.Uid);

      if (response.data.data.length > 0) setNotifications(response.data.data);
      else {
        setNotifications([]);
      }
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to clear all notifications
  const clearAllNotifications = async (userID) => {
    try {
      await deleteAllNotification(userID); // API call to delete all notifications
      setNotifications([]);
    } catch (error) {
      console.error("Failed to delete notifications:", error);
    }
  };

  // Function to delete a single notification
  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotification(notificationId); // API call to delete the notification
      setNotifications((prev) =>
        prev.filter((notification) => notification._id !== notificationId)
      );
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  };

  const isoTimestampToIST = (isoTimestamp) => {
    const date = new Date(isoTimestamp);
    const dateInIST = new Date(date.getTime());
    const year = dateInIST.getFullYear();
    const month = String(dateInIST.getMonth() + 1).padStart(2, "0");
    const day = String(dateInIST.getDate()).padStart(2, "0");
    const hours = String(dateInIST.getHours()).padStart(2, "0");
    const minutes = String(dateInIST.getMinutes()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return { date: formattedDate, time: formattedTime };
  };

  // const { date, time } = ;

  const getStatusIcon = (status) => {
    switch (status) {
      case "Approved":
        return <CheckIcon sx={{ color: "green" }} />;
      case "Rejected":
        return <ClearIcon color="error" />;
      case "Renew":
        return <WarningIcon color="warning" />;
      default:
        return null;
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box
        width={400}
        minHeight={400}
        maxHeight={400}
        px={2}
        py={1}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header with title and Clear All button */}
        <Stack direction={"row"} justifyContent={"space-between"} mb={1}>
          <Typography fontSize={16} fontWeight={600}>
            {allTranslations?.notifications?.[userLang] ?? "Notifications"}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: 10,
              textTransform: "capitalize",
              bgcolor: "primary.main",
            }}
            onClick={() => {
              clearAllNotifications(profile.Uid);
            }}
            disabled={notifications.length === 0 || loading}
          >
            {allTranslations?.clear_all?.[userLang] ?? "Clear all"}
          </Button>
        </Stack>

        {/* Notifications content area (scrollable) */}
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            maxHeight: 400, // Adjust as needed to ensure scrollable area
          }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
              mt={2}
            >
              <CircularProgress size={24} />
            </Box>
          ) : notifications.length > 0 ? (
            <>
              {notifications.map((data) => (
                <>
                  <MenuItem key={data._id} sx={{ mt: 1 }}>
                    <Stack direction={"row"} gap={2}>
                      {getStatusIcon(data.status)}{" "}
                      {/* Status icon based on notification */}
                      <Box flex={1}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          mb={1}
                        >
                          <Typography fontWeight={600} fontSize={14}>
                            {data.status}
                          </Typography>
                          <Typography fontSize={12}>
                            {isoTimestampToIST(data.timestamp).time}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            textAlign: "justify",
                            fontSize: 12,
                          }}
                        >
                          {data.message}
                        </Typography>
                        <Typography mt={1} fontSize={12}>
                          {/* {date} */}
                          {isoTimestampToIST(data.timestamp).date}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => handleDeleteNotification(data._id)}
                        sx={{
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Stack>
                  </MenuItem>
                  <Divider
                    sx={{ width: "100%", borderColor: "rgb(202, 202, 202)" }}
                  />
                </>
              ))}
            </>
          ) : (
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
              minHeight={300}
            >
              <Typography fontSize={14} fontWeight={600}>
                {allTranslations?.no_notifications?.[userLang] ??
                  "No Notifications"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Menu>
  );
};

export default Notifications;

import {
  Button,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import Summary from "./Summary";
import { tileShadows } from "../../pages/Root";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
const Comments = ({
  addedItems,
  cart,
  requestType,
  copyRequestDetails,
  selectedUserDetails,
  setCart,
  setAddedItems,
  selectedOtherUserDetails,
}) => {
  const [comments, setComments] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [resumeError] = useState("");
  const [fileList] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const filterFiles = (fName) => {
    const updatedFiles = fileNames.filter((eachFile) => eachFile !== fName);
    const updatedSelectedFiles = selectedFiles.filter(
      (eachFile) => eachFile.name !== fName
    );
    setFileNames(updatedFiles);
    setSelectedFiles(updatedSelectedFiles);
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFileNames = [...files].map((f) => f.name);
    const formData = new FormData();
    [...files].forEach((item) => {
      formData.append("file", item);
    });
    setSelectedFiles([...files]);
    setFileNames([...fileNames, ...newFileNames]);
    if (!fileList) {
      return;
    }
  };
  const handleClearAll = () => {
    setCart([]);
    setComments("");
    setAddedItems({});
    setFileNames([]);
    setSelectedFiles([]);
  };

  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      spacing={4}
      sx={{
        // mt: "48px",
        borderRadius: "5px",
        py: 1,
      }}
    >
      <Stack
        borderRadius={3}
        p={3}
        alignItems={"flex-start"}
        sx={{
          bgcolor: theme.palette.tiles.bg,
          width: "70%",
          border: `1px solid ${theme.palette.primary.border}`,
          ...tileShadows,
        }}
      >
        {/* Comments Box */}
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={
            allTranslations?.type_your_comments_here?.[userLang] ??
            "Type your comments here..."
          }
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          multiline
          sx={{
            width: "100%",
            minHeight: 100,
            maxHeight: 150,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "2px",
              bgcolor: theme.palette.icons.bgLight,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "icons.bgDark",
              borderRadius: 5,
            },
            p: 1.5,
            // border: "1px solid gray",
          }}
        />
        <Divider
          sx={{
            width: "100%",
            my: 2,
            borderColor: theme.palette.primary.border,
          }}
        />
        {/* Uploaded Files */}
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Tooltip
            title={allTranslations?.attach_files?.[userLang] ?? "Attach files"}
          >
            <IconButton component="label" sx={{ mt: 1 }} htmlFor="file-input">
              <AttachFileIcon sx={{ color: theme.palette.text.thin }} />
            </IconButton>
          </Tooltip>
          <input
            id="file-input"
            type="file"
            multiple
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            onChange={handleFileChange}
            hidden
          />
          <Stack
            maxHeight={50}
            direction={"row"}
            flexWrap={"wrap"}
            columnGap={2}
            width={"100%"}
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "2px",
                bgcolor: theme.palette.icons.bgLight,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "icons.bgDark",
                borderRadius: 5,
              },
            }}
          >
            {fileNames &&
              fileNames.map((fileName, i) => {
                return (
                  <Stack direction={"row"} alignItems={"center"} key={i}>
                    <Typography
                      variant="caption"
                      component={"span"}
                      display="block"
                      sx={{ color: theme.palette.text.light, lineHeight: 0.5 }}
                    >
                      {fileName}
                    </Typography>
                    <IconButton onClick={() => filterFiles(fileName)}>
                      <Close
                        sx={{
                          color: theme.palette.text.light,
                          fontSize: "15px",
                        }}
                      />
                    </IconButton>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
        {resumeError && (
          <Typography
            variant="caption"
            component={"span"}
            display="block"
            mt={1}
            sx={{ color: "#cd4949" }}
          >
            Please upload File
          </Typography>
        )}
      </Stack>

      {/* Clear and Next Buttons */}
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
      >
        <Button
          variant="contained"
          // disableElevation
          onClick={(e) => {
            e.stopPropagation();
            handleClearAll();
          }}
          disabled={submitLoading}
          sx={{
            px: 2,
            bgcolor: theme.palette.background.default,
            color: theme.palette.text.thin,
            border: `1px solid ${theme.palette.primary.border}`,
            ":hover": {
              bgcolor: theme.palette.icons.bgDark,
              color: theme.palette.icons.colorActive,
              border: `1px solid ${theme.palette.icons.bgDark}`,
            },
            boxShadow: "none",
          }}
        >
          {allTranslations?.clear_all?.[userLang] ?? "CLEAR ALL"}
        </Button>
        <Button
          variant="outlined"
          disableElevation
          disabled={
            (Object.entries(addedItems).length &&
            comments &&
            selectedOtherUserDetails
              ? false
              : true) || submitLoading
          }
          sx={{
            px: 3,
            bgcolor: theme.palette.icons.bgDark,
            color: theme.palette.text.white,
            border: `1px solid ${theme.palette.icons.bgDark}`,
            ":hover": {
              border: `1px solid ${theme.palette.primary.border}`,
              bgcolor: theme.palette.background.default,
              color: theme.palette.text.thin,
            },
          }}
          onClick={() => setIsActive(true)}
        >
          {allTranslations?.next?.[userLang] ?? "NEXT"}
        </Button>
        <Summary
          isActive={isActive}
          setIsActive={setIsActive}
          cart={cart}
          comments={comments}
          selectedUserDetails={selectedUserDetails}
          fileNames={fileNames}
          selectedFiles={selectedFiles}
          requestType={requestType}
          // handleSubmit={handleSubmit}
          submitLoading={submitLoading}
          setSubmitLoading={setSubmitLoading}
          copyRequestDetails={copyRequestDetails}
        />
      </Stack>
    </Stack>
  );
};

export default Comments;

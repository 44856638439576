import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ProductsTable from "../components/common/productsTable/ProductsTable";
import ProductFilters from "../components/newRequest/ProductFilters";
import Comments from "../components/newRequest/Comments";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomizedSwitches from "../components/common/switch/Switch";
import UserFilters from "../components/peerRequest/UserFilters";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  fetchRequestDetails,
  getProductDetails,
  getUserProductDetails,
} from "../apiCalls/allApiCalls";
import { toast } from "react-toastify";

function MainComponent({ requestType }) {
  const navigate = useNavigate();
  const { allTranslations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  let { id: copyRequestId } = useParams();
  const location = useLocation();
  const path = location.pathname;
  console.log(path, "path");
  const [copyRequestDetails, setCopyRequestDetails] = useState("");

  // User States
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [selectedPeerUserDetails, setSelectedPeerUserDetails] = useState();
  const [selectedOtherUserDetails, setSelectedOtherUserDetails] = useState();

  console.log(
    selectedOtherUserDetails,
    selectedPeerUserDetails,
    "selectedOtherUserDetails"
  );

  // Product filters
  const [initial, setInitial] = useState(true);
  const [productFilters, setProductFilters] = useState({
    platform: "",
    system: "",
    search: "",
    role: "",
  });

  // For all products details
  const [productDetails, setProductDetails] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [, setFilterLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [addedItems, setAddedItems] = useState({});
  const [checked, setChecked] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    sessionStorage.setItem("bulkUpload", true);
    navigate("/bulk-upload");
  };

  async function fetchProductDetails() {
    setIsProductsLoading(true);
    const response = await getProductDetails();
    if (response?.status === 200) {
      setProductDetails(response.data);
    }
    setIsProductsLoading(false);
  }

  async function fetchUserProducts(uid) {
    setIsProductsLoading(true);
    const response = await getUserProductDetails(uid);
    if (response) {
      setProductDetails(response);
    }
    setIsProductsLoading(false);
  }
  const hasFilters =
    productFilters.platform ||
    productFilters.system ||
    productFilters.search ||
    productFilters.role;
  function filterProducts(productsList) {
    setFilterLoading(true);
    const prods = hasFilters
      ? productsList.filter(
          (product) =>
            (!productFilters.platform ||
              product.productDetails.platform.includes(
                productFilters.platform
              )) &&
            (!productFilters.system ||
              product.productDetails.system.includes(productFilters.system)) &&
            (!productFilters.search ||
              product.productDetails.productName
                .toLowerCase()
                .includes(productFilters.search.toLowerCase())) &&
            (!productFilters.role ||
              product.productDetails.approver
                .toLowerCase()
                .includes(productFilters.role.toLowerCase()))
        )
      : [];
    setFilteredProducts(prods);
    setFilterLoading(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsPageLoading(true);
      if (requestType === "peerRequest" || requestType === "deprovisioning") {
        if (selectedPeerUserDetails) {
          await fetchUserProducts(selectedPeerUserDetails?.Uid);
        }
      } else {
        await fetchProductDetails();
      }
      if (copyRequestId) {
        await fetchCopyRequestDetails();
      }
      setIsPageLoading(false);
    };
    fetchData();
  }, [selectedPeerUserDetails, selectedUserDetails, path, copyRequestId]);

  useEffect(() => {
    if (productDetails && productDetails.length > 0) {
      filterProducts(productDetails);
    }
  }, [productFilters, hasFilters, productDetails]);

  async function fetchCopyRequestDetails() {
    setIsProductsLoading(true);
    const response = await fetchRequestDetails(copyRequestId);
    if (response?.status === 200) {
      const reqDetails = response?.data;
      const products = reqDetails?.requestData?.productsData;
      setCopyRequestDetails(reqDetails);
      setFilteredProducts(products);
      setCart(products);
      products.forEach((product) => {
        addedItems[product.productId] = true;
      });
      setInitial(false);
    }
    setIsProductsLoading(false);
  }

  useEffect(() => {
    if (
      selectedPeerUserDetails?.Uid &&
      selectedPeerUserDetails?.Uid === selectedOtherUserDetails?.Uid
    ) {
      toast.error("Both selected Peer User and Other User cannot be the same.");
      setSelectedOtherUserDetails(null);
    }
  }, [selectedPeerUserDetails, selectedOtherUserDetails]);
  useEffect(() => {
    if (requestType === "peerRequest") {
      setSelectedUserDetails(selectedOtherUserDetails);
    }
  }, [selectedOtherUserDetails, requestType]);
  if (isPageLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1300,
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        padding: "20px",
        minWidth: "90vw",
        minHeight: "100vh",
        margin: 5,
        borderRadius: 3,
        border: `1px solid`,
        borderColor: "primary.border",
      }}
    >
      <MiniMenu
        cart={cart}
        setCart={setCart}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
      />
      {/*============ BreadCrumbs ============ */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={12}
        mb={6}
        ml={1}
      >
        <BreadCrumbs />
        {requestType === "newRequest" && (
          <CustomizedSwitches
            checked={checked}
            handleSwitchChange={handleSwitchChange}
          />
        )}
      </Stack>

      <>
        {/*============ User-Details ============*/}

        <Typography
          sx={{
            color: "text.primary",
            fontWeight: 600,
            fontSize: 18,
            mb: 1,
            mt: 3,
          }}
        >
          {requestType === "peerRequest"
            ? allTranslations?.peer_user?.[userLang] +
                " " +
                allTranslations?.details?.[userLang] || "Peer User Details"
            : allTranslations?.user_details?.[userLang] || "User Details"}
          <span
            style={{
              color: "tomato",
            }}
          >
            *
          </span>{" "}
        </Typography>
        <UserFilters
          setSelectedUserDetails={
            requestType === "peerRequest" || requestType === "deprovisioning"
              ? setSelectedPeerUserDetails
              : setSelectedUserDetails
          }
          selectedUserDetails={
            requestType === "peerRequest" || requestType === "deprovisioning"
              ? selectedPeerUserDetails
              : selectedUserDetails
          }
          requestType={requestType}
        />

        {/*============ Product Details ============*/}
        <Typography
          sx={{
            color: "text.primary",
            fontWeight: 600,
            fontSize: 18,
            mb: 1,
            mt: 3,
          }}
        >
          {allTranslations?.product_details?.[userLang] ?? "Product Details"}
          <span
            style={{
              color: "tomato",
            }}
          >
            *
          </span>{" "}
        </Typography>
        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid`,
            borderColor: "primary.border",
          }}
        >
          {/* Product Filters */}
          <ProductFilters
            setProductFilters={setProductFilters}
            setInitial={setInitial}
          />
          {/* Product table */}
          <ProductsTable
            data={filteredProducts}
            setFilteredProducts={setFilteredProducts}
            initial={initial}
            setCart={setCart}
            cart={cart}
            addedItems={addedItems}
            setAddedItems={setAddedItems}
            requestType={requestType}
          />
        </Box>

        {/* second user filters for peer user */}
        {location.pathname.includes("peer-request") && (
          <>
            <Typography
              sx={{
                color: "text.primary",
                fontWeight: 600,
                fontSize: 18,
                mb: 1,
                mt: 3,
              }}
            >
              {allTranslations?.user_details?.[userLang] ?? "User Details"}
              <span
                style={{
                  color: "tomato",
                }}
              >
                *
              </span>{" "}
              <Tooltip
                title={
                  allTranslations?.enter_peer_user_details_first?.[userLang] ??
                  "Enter Peer user Details First"
                }
              >
                <InfoOutlinedIcon
                  sx={{
                    fontSize: 20,
                    // ml: 1,
                    color: "info.main",
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Typography>
            <UserFilters
              setSelectedUserDetails={setSelectedOtherUserDetails}
              selectedUserDetails={selectedOtherUserDetails}
              requestType={requestType}
              isDisabled={!selectedPeerUserDetails}
            />
          </>
        )}
        {/*============ Comments & Btns Section ============*/}
        <Typography
          sx={{
            color: "text.primary",
            fontWeight: 600,
            fontSize: 18,
            mb: 1,
            mt: 3,
          }}
        >
          {allTranslations?.comments?.[userLang] ?? "Comments"}
          <span
            style={{
              color: "tomato",
            }}
          >
            *
          </span>{" "}
          & {allTranslations?.attachments?.[userLang] ?? "Attachments"} :
        </Typography>

        <Comments
          addedItems={addedItems}
          cart={cart}
          selectedOtherUserDetails={selectedOtherUserDetails}
          copyRequestDetails={copyRequestDetails}
          selectedUserDetails={selectedUserDetails}
          setCart={setCart}
          setAddedItems={setAddedItems}
          requestType={requestType}
        />
      </>
      {/* )} */}
    </Box>
  );
}

export default MainComponent;
